import React from 'react'
import styled from 'styled-components'

import Player from 'components/shared/player'

import media from 'styles/media'
import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  width: 100%;
  height: 440px;

  ${media.lg.min} {
    height: 100vh;
  }
`

type Props = {
  videoSrcUrl: string
  videoTitle: string
  videoThumbnailImage?: {
    src: ImageDataLike
    alt: string
  }
}

const VideoSection: React.FC<Props> = ({
  videoSrcUrl,
  videoTitle,
  videoThumbnailImage,
}) => {
  return (
    <Wrapper>
      <Player
        videoSrcUrl={videoSrcUrl}
        videoTitle={videoTitle}
        videoThumbnailImage={videoThumbnailImage}
      />
    </Wrapper>
  )
}

export default VideoSection
